<template>
  <div>
    <div class="read-fault-button">
      <el-button type="info" @click="readFault"
                 :disabled="!isMotorConnected   || isMotorConnecting || isBatteryConnecting || isDisplayConnecting"
                 :class="{ 'custom-button': true, 'button-active': isMotorConnected && !isMotorConnecting && !isBatteryConnecting && !isDisplayConnecting}">

        <el-icon>
          <CaretRight/>
        </el-icon>
        {{ $t('ProductDiagnosis.readFault') }}
      </el-button>&nbsp;&nbsp;
      <span>{{ $t('ProductDiagnosis.ensureBatteryIsOn') }}</span>
    </div>

    <div class="table-container">

      <el-table v-loading="isLoading" element-loading-text="Loading..." :header-cell-style="{background:'rgb(250, 250, 250)'}" :data="faultData" style="width: 100%">
        <el-table-column :label="$t('ProductDiagnosis.sequenceNumber')" type="index" :min-width="100"></el-table-column>
        <el-table-column :label="$t('ProductDiagnosis.module')">
          <template v-slot="scope">
            {{ scope.row ? scope.row.moduleType : '-' }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('ProductDiagnosis.faultName')" prop="errName"></el-table-column>
        <el-table-column :label="$t('ProductDiagnosis.faultTime')">
          <template v-slot="scope">
            {{ checkDate(scope.row?.date) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('ProductDiagnosis.faultDetails')" prop="detail" width="700px">
          <template v-slot="scope">
            <el-button link @click="viewHistoryDetails(scope.row)"><span
                class="link-text-style">{{ $t('ProductDiagnosis.view') }}</span>
            </el-button>
          </template>
        </el-table-column>

        <!-- 使用 empty 插槽显示图片 -->
        <template v-slot:empty>
          <EmptySlot/>
        </template>

      </el-table>

    </div>

    <el-dialog v-model="historyDetailVisible" :width="700" @open="disableScroll" @close="enableScroll">
      <template #header>
        <div class="my-header">
          {{ $t('ProductDiagnosis.faultDetails') }}
        </div>
        <hr class="dialog-separator"/>

        <div class="flex-container">
          <div class="flex-item">
            <p>  <span class="custom-color err-name-large">{{
                motorErrorDetail?.errName || '-'
              }}</span></p>
          </div>
          <div class="flex-item">
            <p> {{ $t('ProductDiagnosis.faultTime') }} <span
                class="custom-color">{{ checkDate(motorErrorDetail?.date) }}</span></p>
          </div>
          <div class="flex-item">
            <p> {{ $t('ProductDiagnosis.totalFaultsCount') }} <span
                class="custom-color">{{ checkErrCount(motorErrorDetail?.errCount) }}</span></p>
          </div>
        </div>

        <div class="section">
          <hr class="dialog-separator"/>
          <div class="battery-flex-container">
            <div class="battery-flex-item">
              <p> {{ $t('ProductDiagnosis.gearValue') }} <span class="custom-color">{{
                  motorErrorDetail?.gearlevel !== null && motorErrorDetail.gearlevel !== '' && motorErrorDetail.gearlevel <= 9 && motorErrorDetail.gearlevel >= 0 ? motorErrorDetail.gearlevel : '-'
                }} </span></p>
            </div>
            <div class="battery-flex-item">
              <p>SOC <span class="custom-color">{{
                  motorErrorDetail?.batterquantity !== null && motorErrorDetail.batterquantity !== '' && motorErrorDetail.batterquantity >= 0  && motorErrorDetail.batterquantity <= 100 ? motorErrorDetail.batterquantity + ' %' : '-'
                }}</span></p>

            </div>
            <div class="battery-flex-item">
              <p>{{ $t('ProductDiagnosis.motorTemperature') }} <span class="custom-color">{{
                  motorErrorDetail?.temperature_motor !== null && motorErrorDetail.temperature_motor !== '' && motorErrorDetail.temperature_motor >= 0  && motorErrorDetail.temperature_motor <= 100 ? motorErrorDetail.temperature_motor + ' ℃' : '-'
                }}</span></p>

            </div>
            <div class="battery-flex-item">
              <p>{{ $t('ProductDiagnosis.speed') }} <span class="custom-color">{{
                  motorErrorDetail?.bikespeed !== null && motorErrorDetail.bikespeed !== '' && motorErrorDetail.bikespeed >= 0  && motorErrorDetail.bikespeed <= 60 ? motorErrorDetail.bikespeed + ' km/h' : '-'
                }}</span></p>
            </div>
            <div class="battery-flex-item">
              <p>{{ $t('ProductDiagnosis.BMSTemperature') }} <span class="custom-color">{{
                  motorErrorDetail?.temperature_BMS !== null && motorErrorDetail.temperature_BMS !== '' && motorErrorDetail.temperature_BMS >= 0 && motorErrorDetail.temperature_BMS <= 40 ? motorErrorDetail.temperature_BMS + ' ℃' : '-'
                }}</span></p>
            </div>
            <div class="battery-flex-item">
              <p>{{ $t('ProductDiagnosis.motorRPM') }} <span class="custom-color">{{
                  motorErrorDetail?.RPM !== null && motorErrorDetail.RPM !== '' && motorErrorDetail.RPM >= 0 && motorErrorDetail.RPM <= 120 ? motorErrorDetail.RPM + ' rpm' : '-'
                }}</span></p>

            </div>
            <div class="battery-flex-item">
              <p>{{ $t('ProductDiagnosis.cellTemperature') }} <span class="custom-color">{{
                  motorErrorDetail?.temperature_core !== null && motorErrorDetail.temperature_core !== '' && motorErrorDetail.temperature_core >= 0 && motorErrorDetail.temperature_core <= 100 ? motorErrorDetail.temperature_core + ' ℃' : '-'
                }}</span></p>

            </div>
            <div class="battery-flex-item">
              <p>{{ $t('ProductDiagnosis.busCurrent') }} <span class="custom-color">{{
                  motorErrorDetail?.busCurrent !== null && motorErrorDetail.busCurrent !== '' && motorErrorDetail.busCurrent >= 0 && motorErrorDetail.busCurrent <= 40? motorErrorDetail.busCurrent + ' A' : '-'
                }}</span></p>

            </div>
            <div class="battery-flex-item">
              <p>{{ $t('ProductDiagnosis.totalMileage') }} <span class="custom-color">{{
                  motorErrorDetail?.ODO !== null && motorErrorDetail.ODO !== '' && motorErrorDetail.ODO >= 0  && motorErrorDetail.ODO <= 100000 ? motorErrorDetail.ODO + ' km' : '-'
                }}</span></p>

            </div>
            <div class="battery-flex-item">
              <p>{{ $t('ProductDiagnosis.controllerTemperature') }} <span class="custom-color">{{
                  motorErrorDetail?.temperature_controller !== null && motorErrorDetail.temperature_controller !== '' && motorErrorDetail.temperature_controller >= 0 && motorErrorDetail.temperature_controller <= 100 ? motorErrorDetail.temperature_controller + ' ℃' : '-'
                }}</span></p>

            </div>
            <div class="battery-flex-item">
              <p>{{ $t('ProductDiagnosis.phaseCurrent') }} <span class="custom-color">{{
                  motorErrorDetail?.phaseCurrent !== null && motorErrorDetail.phaseCurrent !== '' && motorErrorDetail.phaseCurrent >= 0 && motorErrorDetail.phaseCurrent <= 100 ? motorErrorDetail.phaseCurrent + ' A' : '-'
                }}</span></p>

            </div>
          </div>
        </div>

      </template>
    </el-dialog>

  </div>
</template>

<script>
import {CaretRight} from "@element-plus/icons-vue";
import EventBus from "@/eventBus";
import dialogMixin from "@/mixins/dialogMixin";
import EmptySlot from "@/components/EmptySlot.vue";

export default {
  mixins: [dialogMixin],
  name: "HistoryFault",
  components: {EmptySlot, CaretRight},
  data() {
    return {
      deviceError: {
        '01': { type: 1, code: 1, zh: "控制器相线过流故障", en: "Controller phase overcurrent fault" },
        '02': { type: 1, code: 2, zh: "控制器母线过流故障", en: "Controller bus overcurrent fault" },
        '03': { type: 1, code: 3, zh: "控制器HALL故障", en: "Controller HALL fault" },
        '05': { type: 1, code: 5, zh: "控制器温度故障", en: "Controller temperature fault" },
        '07': { type: 1, code: 7, zh: "过载故障", en: "Overload fault" },
        '30': { type: 0, code: 30, zh: "通讯仪表故障", en: "Communication meter fault" },
        '60': { type: 0, code: 60, zh: "仪表MCU故障、电压基准故障", en: "Meter MCU fault, voltage reference fault" },
        '06': { type: 1, code: 6, zh: "电机温度故障", en: "Motor temperature fault" },
        '20': { type: 1, code: 20, zh: "推行、电源按键故障", en: "Push, power button fault" },
        '04': { type: 1, code: 4, zh: "刹车故障，转把故障", en: "Brake fault, handle fault" },
        '10': { type: 1, code: 10, zh: "其它故障", en: "Other faults" },
        '08': { type: 1, code: 8, zh: "控制器通讯故障", en: "Controller communication fault" },
        '09': { type: 1, code: 9, zh: "控制器过压，欠压", en: "Controller overvoltage, undervoltage" },
        '31': { type: 1, code: 31, zh: "MCU故障，基准电压故障，计算周期故障", en: "MCU fault, reference voltage fault, calculation period fault" },
        '32': { type: 0, code: 32, zh: "TE 芯片通讯故障", en: "TE chip communication fault" },
        '34': { type: 0, code: 34, zh: "按键故障", en: "Button fault" },
        '35': { type: 0, code: 35, zh: "按键故障", en: "Button fault" },
        '36': { type: 1, code: 36, zh: "力矩电压故障", en: "Torque voltage fault" },
        '37': { type: 1, code: 37, zh: "刹车故障，车速传感器故障，检测 HALL 故障", en: "Brake fault, speed sensor fault, HALL detection fault" },
        '38': { type: 1, code: 38, zh: "温度传感器故障", en: "Temperature sensor fault" },
        '39': { type: 1, code: 39, zh: "基准采样超时", en: "Reference sampling timeout" },
        '40': { type: 1, code: 40, zh: "电流反馈电路故障", en: "Current feedback circuit fault" },
        '41': { type: 1, code: 41, zh: "驱动电压故障", en: "Drive voltage fault" },
        '42': { type: 1, code: 42, zh: "电流异常故障", en: "Current abnormality fault" },
        '43': { type: 1, code: 43, zh: "电机缺相故障，电机启动停止超时故障", en: "Motor phase loss fault, motor start stop timeout fault" },
        '50': { type: 1, code: 50, zh: "力矩电压检测电路异常", en: "Torque voltage detection circuit abnormality" },
        '61': { type: 0, code: 61, zh: "仪表蓝牙故障", en: "Meter Bluetooth fault" },
        '62': { type: 0, code: 62, zh: "仪表感光故障", en: "Meter photosensitive fault" }
      },

      motorErrorDetail: {},
      historyDetailVisible: false,
      faultData: [], // 故障数据，需要从后端获取或者填充
      isLoading: false,
    }
  },
  computed: {
    isMotorConnecting() {
      return this.$store.state.isMotorConnecting;
    },
    isBatteryConnecting() {
      return this.$store.state.isBatteryConnecting;
    },
    isDisplayConnecting() {
      return this.$store.state.isDisplayConnecting;
    },
    isMotorConnected() {
      return this.$store.state.isMotorConnected;
    },
    isDisplayConnected() {
      return this.$store.state.isDisplayConnected;
    },
  },
  watch : {
    '$i18n.locale'() {
        this.readFault();
    },
  },
  mounted() {
    EventBus.on('reset-all-data', this.clearData);
    EventBus.on('HisErrorResponse', this.handleHisErrorResponse);
  },
  beforeUnmount() {
    EventBus.off('reset-all-data', this.clearData);
    EventBus.off('HisErrorResponse', this.handleHisErrorResponse);
  },
  methods: {
    checkDate(date) {
      const parsedDate = new Date(date);
      if (!date || isNaN(parsedDate.getTime())) return '-';
      const oneYearAgo = new Date();
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
      return parsedDate < oneYearAgo ? '-' : date;
    },
    checkErrCount(count) {
      if (!count || count > 20 || count < 0) return '-';
      return count;
    },
    clearData() {
      this.faultData = [];
    },
    async handleHisErrorResponse(message) {
      try {
        if (message.code === 200) {
          this.faultData = JSON.parse(message.data);
          await this.fetchErrorNames(this.faultData);
        } else {
          this.faultData = [];
        }
        this.isLoading = false; // 数据处理完毕，停止加载
      } catch (error) {
        console.error("An error occurred while processing the fault data:", error);
        this.isLoading = false;
        this.faultData = [];
      }
    },
    async readFault() {
      this.isLoading = true; // 开始加载数据
      const ws = this.$store.state.ws;
      if (!ws || ws.readyState !== WebSocket.OPEN) {
        console.error("WebSocket is not connected.");
        this.isLoading = false;
        return;
      }
      // 定义获取电机信息的命令
      const getMotorInfoHisErrorCommand = {
        "cmd": "getMotorInfo_HisError",
        "param": ""
      };
      ws.send(JSON.stringify(getMotorInfoHisErrorCommand));
    },
    fetchErrorNames(faultArray) {
      console.log(111736446477373)
      console.log(faultArray)
      console.log(111736446477373)
      const currentLocale = this.$i18n.locale;

      this.faultData = faultArray.map(fault => {
        const errorData = this.deviceError[fault.errCode];
        if (errorData) {
          let errName;
          if (currentLocale === 'zh') {
            errName = errorData.zh;
          } else if (currentLocale === 'en') {
            errName = errorData.en;
          } else {
            errName = errorData.zh; // 其他语言默认显示英文
          }
          return {
            ...fault,
            errName,
            moduleType: errorData.type === 1 ? this.$t('ProductDiagnosis.motor') : this.$t('ProductDiagnosis.dashboard')
          };
        }
      }).filter(item => item !== undefined); // 过滤掉所有的 undefined 值

      this.$emit('update-history-fault-data', this.faultData);
    },

    viewHistoryDetails(row) {
      this.motorErrorDetail = row;
      this.historyDetailVisible = true;
    },

  }
}
</script>

<style scoped>

.el-table__header th.el-table-column--index {
  writing-mode: horizontal;
}

.table-container {
  position: relative;
  height: 600px; /* 根据实际高度调整 */
  overflow-y: auto;
}

.read-fault-button {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.battery-flex-container {
  color: rgba(0, 0, 0, 0.45); /* 设置文字颜色和透明度 */
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  /* 允许换行 */
  margin-bottom: 0;
  /* 设置行间距 */
}

.battery-flex-item p {
  padding: 1px;
}

.battery-flex-item {
  flex: 0 0 calc(33.33% - 10px);
  /* 让每个参数占据 33.33% 的宽度，并设置右边距 */
  margin-right: 10px;
  margin-bottom: 0;
}

/* 清除每行最后一个参数的右边距 */
.battery-flex-item:nth-child(3n) {
  margin-right: 0;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.section {
  margin-bottom: 20px;
}

.flex-container {
  color: rgba(0, 0, 0, 0.45); /* 设置文字颜色和透明度 */
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-item {
  width: calc(33.33% - 10px);
  /* 让每个 flex-item 占据 33.33% 的宽度，并留有一些间距 */
  padding: 10px;
}

.err-name-large {
  font-size: 20px; /* 或者你想要的任何大小 */
}

.custom-color {
  color: #000000D9;
}

.custom-button {
  background-color: rgb(245, 245, 245); /* 设置背景色为红色 */
  border-color: rgb(217, 217, 217); /* 设置边框颜色为红色，如果需要 */
  color: rgb(184, 184, 184); /* 设置边框颜色为红色，如果需要 */
}

.custom-button:hover {
  background-color: rgb(245, 245, 245); /* 鼠标悬停时保持相同的背景色 */
  border-color: rgb(217, 217, 217); /* 鼠标悬停时保持相同的边框颜色 */
  color: rgb(184, 184, 184); /* 鼠标悬停时保持相同的文字颜色 */
}

/* 当按钮可点击时应用的样式 */
.button-active {
  background-color: rgb(0, 154, 68); /* 设置背景为白色 */
  color: white; /* 可以调整文字颜色以保持可读性 */
  border-color: #dcdfe6; /* 可选：设置边框颜色 */
}

/* 悬停在 .button-active 上时的样式 */
.button-active:hover {
  color: white; /* 悬停时文字颜色为绿色 */
  border-color: rgb(0, 154, 68); /* 悬停时边框颜色为绿色 */
  background-color: rgb(0, 154, 68); /* 悬停时背景色为白色 */
}

</style>