<template>
  <div class="report-container" ref="reportContainer">
    <!-- 你的报告内容结构 -->
    <div class="report-header">
      <div class="content-wrapper">
        <span class="main-title">{{ $t('ProductDiagnosis.reportTitle') }}</span>
      </div>
      <p>{{ currentTime }}</p>
    </div>
    <hr/>
    <div class="info-section">
      <!-- 动态显示选中的类型信息 -->
      <div v-if="loadedReportData.customerInfo?.types">
        <div v-for="type in loadedReportData.customerInfo.types" :key="type.type">
          <p class="title-color">{{ getLabel(type.type) }} <span class="custom-color"> {{ type.name || '-' }}</span></p>
        </div>
      </div>
      <div>
        <p class="title-color">{{ $t('ProductDiagnosis.contactPerson') }} <span
            class="custom-color"> {{ loadedReportData.customerInfo?.contactPerson || '-' }}</span></p>
        <p class="title-color"> {{ $t('ProductDiagnosis.email') }} <span
            class="custom-color"> {{ loadedReportData.customerInfo?.email || '-' }}</span></p>
        <p class="title-color"> {{ $t('ProductDiagnosis.phone') }} <span
            class="custom-color"> {{ loadedReportData.customerInfo?.phone || '-' }}</span></p>
      </div>
    </div>
    <hr/>

    <div>
      <div class="content-wrapper">
        <img :src="motorImgUrl" width="40" height="40" alt="motor"/>
        <span class="motor-font">{{ $t('ProductDiagnosis.motor') }}</span>
      </div>

      <div class="flex-container">
        <div>
          <p class="title-color">{{ $t('ProductDiagnosis.model') }} <span class="custom-color">{{
              motorTypeAndModelData?.model || '-'
            }}</span>
          </p>
          <p class="title-color">{{ $t('ProductDiagnosis.softwareVersion') }} <span
              class="custom-color">{{ motorInfo?.softwareversion || '-' }}</span></p>
          <p class="title-color"> {{ $t('ProductDiagnosis.kilometers') }} <span class="custom-color">{{
              motorConfigData?.totalkilometer || '-'
            }} km</span>
          </p>
          <p class="title-color"> {{ $t('ProductDiagnosis.maxGear') }} <span class="custom-color">{{
              motorConfigData?.maxLevel || '-'
            }}</span></p>

          <div class="flex-item" v-if="displayedLevels && displayedLevels.length > 0">
            <template v-for="(level,index) in displayedLevels" :key="'currentLevel' + index">
              <p class="title-color">{{ $t('ProductDiagnosis.currentLevel') }}{{ index + 1 }} <span
                  class="custom-color">{{ level.currentLevel }} A</span></p>
            </template>
          </div>

        </div>

        <div>
          <p class="title-color"> {{ $t('ProductDiagnosis.serialNumber') }}<span
              class="custom-color">{{ motorInfo?.motorSerialNumber || '-' }}</span></p>
          <p class="title-color">{{ $t('ProductDiagnosis.hardwareVersion') }} <span
              class="custom-color">{{ motorInfo?.bootloaderversion || '-' }}</span></p>
          <p class="title-color"> {{ $t('ProductDiagnosis.circumference') }} <span class="custom-color">{{
              motorConfigData?.wheelCircumference || '-'
            }} mm</span></p>
          <div class="flex-item" v-if="displayedLevels && displayedLevels.length > 0">
            <template v-for="(level,index) in displayedLevels" :key="'speedLevel' + index">
              <p class="title-color"> {{ $t('ProductDiagnosis.level') }}{{ index + 1 }} <span
                  class="custom-color">{{ level.speedLevel }} km/h</span></p>
            </template>

          </div>
        </div>
      </div>

      <div v-if="motorConfigData?.externaldevices">
        <div style="text-align: left">{{ $t('ProductDiagnosis.peripherals') }}</div>
        <ul class="external-devices-list">
          <li v-for="(device,index) in motorConfigData?.externaldevices" :key="device">
            {{ deviceName(device) }}
            <template v-if="index !== motorConfigData?.externaldevices.length - 1">,</template>
          </li>
        </ul>
      </div>

    </div>
    <hr/>
    <div>
      <div class="content-wrapper">
        <img :src="batteryImgUrl" width="40" height="40" alt="batteryImg"/>
        <span class="motor-font">{{ $t('ProductDiagnosis.battery') }}</span>
      </div>

      <div class="flex-container">
        <div>
          <p class="title-color">{{ $t('ProductDiagnosis.model') }}
            <span class="custom-color">{{ batteryInfo?.model || '-' }}</span></p>
          <p class="title-color">{{ $t('ProductDiagnosis.softwareVersion') }}
            <span class="custom-color">{{ batteryInfo?.firmwareversion || '-' }}</span></p>
          <p class="title-color">{{ $t('ProductDiagnosis.voltage') }}
            <span class="custom-color">{{ batteryInfo?.BatVoltage || '-' }} V</span></p>
          <p class="title-color">{{ $t('ProductDiagnosis.fullChargeCapacity') }}
            <span class="custom-color">{{ batteryInfo?.BatFullChgCap || '-' }} AH</span></p>
          <p class="title-color">{{ $t('ProductDiagnosis.SOC') }}
            <span class="custom-color">{{ batteryInfo?.BatSoc || '-' }} %</span></p>
          <p class="title-color">{{ $t('ProductDiagnosis.soh') }}
            <span class="custom-color">{{ batteryInfo?.BatSoh || '-' }} %</span></p>


        </div>
        <div>
          <p class="title-color">{{ $t('ProductDiagnosis.serialNumber') }}
            <span class="custom-color">{{ batteryInfo?.sncode || '-' }}</span></p>
          <p class="title-color">{{ $t('ProductDiagnosis.hardwareVersion') }}
            <span class="custom-color">{{ batteryInfo?.hardwareversion || '-' }}</span></p>
          <p class="title-color">{{ $t('ProductDiagnosis.current') }}
            <span class="custom-color">{{ batteryInfo?.BatRealTimeCur || '-' }} mA</span></p>
          <p class="title-color">{{ $t('ProductDiagnosis.chargeDischarge') }}
            <span class="custom-color">{{ batteryInfo?.BatCycles || '-' }}</span></p>
          <p class="title-color">{{ $t('ProductDiagnosis.residualCapacity') }}
            <span class="custom-color">{{ batteryInfo?.BatRemCap || '-' }} AH</span></p>
        </div>
      </div>
    </div>
    <hr/>
    <div>
      <div class="content-wrapper">
        <img :src="dashboardImgUrl" width="40" height="40" alt="dashboardImg"/>
        <span class="motor-font">{{ $t('ProductDiagnosis.dashboard') }}</span>
      </div>

      <div class="flex-container">
        <div>
          <p class="title-color">{{ $t('ProductDiagnosis.model') }}
            <span class="custom-color">{{ displayTypeAndModelData?.model || '-' }}</span></p>
          <p class="title-color">{{ $t('ProductDiagnosis.softwareVersion') }}
            <span class="custom-color">{{ displayInfo?.softwareversion || '-' }}</span></p>
        </div>
        <div>
          <p class="title-color">{{ $t('ProductDiagnosis.serialNumber') }}
            <span class="custom-color">{{ displayInfo?.snCode || '-' }}</span></p>
          <p class="title-color">{{ $t('ProductDiagnosis.hardwareVersion') }}
            <span class="custom-color">{{ displayInfo?.hardwareversion || '-' }}</span></p>
        </div>
      </div>
    </div>
    <hr/>
    <div>
      <div class="content-wrapper">
        <span class="motor-font">{{ $t('ProductDiagnosis.historicalFaults') }}</span>
      </div>
      <div v-if="motorHistoryError && motorHistoryError.length > 0">
        <div v-for="(error,index) in motorHistoryError" :key="index">
          <div class="flex-container">
            <div class="flex-item">
              <p class="title-color">  <span class="custom-color err-name-large">{{
                  error.errName || '-'
                }}</span></p>
            </div>
            <div class="flex-item">
              <p class="title-color"> {{ $t('ProductDiagnosis.faultTime') }} <span
                  class="custom-color">{{ error.date || '-' }}</span></p>
            </div>
            <div class="flex-item">
              <p class="title-color"> {{ $t('ProductDiagnosis.totalFaults') }}<span
                  class="custom-color">{{ error.errCount || '-' }}</span></p>
            </div>
          </div>

          <div class="flex-container">
            <div>
              <p class="title-color"> {{ $t('ProductDiagnosis.gearValue') }}<span class="custom-color">{{
                  error?.gearlevel || '-'
                }} V</span></p>
              <p class="title-color"> {{ $t('ProductDiagnosis.speed') }}<span
                  class="custom-color">{{ error?.bikespeed || '-' }} km/h</span></p>
              <p class="title-color"> {{ $t('ProductDiagnosis.cellTemperature') }} <span
                  class="custom-color">{{ error?.temperature_core || '-' }} ℃</span>
              </p>
              <p class="title-color"> {{ $t('ProductDiagnosis.controllerTemperature') }} <span
                  class="custom-color">{{ error?.temperature_controller || '-' }} ℃</span>
              </p>
            </div>
            <div>
              <p class="title-color"> {{ $t('ProductDiagnosis.batteryTemperature') }} <span class="custom-color">{{
                  error?.temperature_BMS || '-'
                }} ℃</span></p>
              <p class="title-color"> {{ $t('ProductDiagnosis.BMSTemperature') }} <span class="custom-color">{{
                  error?.temperature_BMS || '-'
                }} ℃</span></p>
              <p class="title-color"> {{ $t('ProductDiagnosis.busCurrent') }} <span
                  class="custom-color">{{ error?.busCurrent || '-' }} A</span>
              </p>
              <p class="title-color"> {{ $t('ProductDiagnosis.phaseCurrent') }}<span
                  class="custom-color">{{ error?.phaseCurrent || '-' }} A</span>
              </p>
            </div>

            <div>
              <p class="title-color"> {{ $t('ProductDiagnosis.motorTemperature') }} <span class="custom-color">{{
                  error?.temperature_motor || '-'
                }} ℃</span>
              </p>
              <p class="title-color"> {{ $t('ProductDiagnosis.motorRPM') }} <span
                  class="custom-color">{{ error?.RPM || '-' }} rpm</span></p>
              <p class="title-color"> {{ $t('ProductDiagnosis.totalMileage') }} <span
                  class="custom-color">{{ error?.ODO || '-' }} km</span>
              </p>
            </div>
          </div>
          <hr/>
        </div>
      </div>
      <div v-else>
        <p class="title-color">{{ $t('ProductDiagnosis.noHistoricalData') }}</p>
      </div>
    </div>

    <div>
      <div class="content-wrapper">
        <span class="motor-font">{{ $t('ProductDiagnosis.selfCheck') }}</span>
      </div>

      <el-table :header-cell-style="{background:'rgb(250, 250, 250)'}" :data="selfCheckData" style="width: 100%">
        <el-table-column :label="$t('ProductDiagnosis.sequenceNumber')" type="index" :min-width="100"></el-table-column>
        <el-table-column :label="$t('ProductDiagnosis.module')">
          <template v-slot="scope">
            {{ scope.row.productType === 1 ? $t('ProductDiagnosis.display') : $t('ProductDiagnosis.motor') }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('ProductDiagnosis.selfCheckItems')" prop="errName"
        ></el-table-column>

        <el-table-column :label="$t('ProductDiagnosis.selfCheckResults')">
          <template #default="scope">
            <el-tag
                :type="scope.row.isErrorName === 1 ? 'danger' : scope.row.isErrorName === 2 ? 'warning' : 'success'">
              {{
                scope.row.isErrorName === 1 ? $t('ProductDiagnosis.abnormal') : scope.row.isErrorName === 2 ? $t('ProductDiagnosis.warning') : $t('ProductDiagnosis.normal')
              }}
            </el-tag>

          </template>
        </el-table-column>

        <el-table-column :label="$t('ProductDiagnosis.treatmentMeasures')" prop="measures">
        </el-table-column>

        <el-table-column :label="$t('ProductDiagnosis.helpManual')" prop="manual">
          <template #default="scope">
            <span v-if="!scope.row.manual_link">{{ scope.row.manual }}</span>
            <a v-else :href="scope.row.manual_link" target="_blank"
               style="text-decoration: none;color: rgb(0,154,68)">{{ scope.row.manual }}</a>
          </template>
        </el-table-column>

      </el-table>

    </div>
    <hr/>

    <div>
      <div class="content-wrapper">
        <span class="motor-font">{{ $t('ProductDiagnosis.dynamicTesting') }}</span>
      </div>
      <el-table :header-cell-style="{background:'rgb(250, 250, 250)'}" :data="dynamicData" style="width: 100%">
        <el-table-column :label="$t('ProductDiagnosis.sequenceNumber')" type="index" :min-width="100"></el-table-column>
        <el-table-column :label="$t('ProductDiagnosis.module')" prop="module"></el-table-column>
        <el-table-column :label="$t('ProductDiagnosis.testContent')" prop="testContent"
        ></el-table-column>
        <el-table-column :label="$t('ProductDiagnosis.testValue')" prop="testData"
        ></el-table-column>
        <el-table-column :label="$t('ProductDiagnosis.testResult')">
          <template #default="scope">
    <span :style="{ color: scope.row.isErrorName === 1 ? 'red' : 'rgb(0,154,68)' }">
            {{ scope.row.isErrorName === 1 ? $t('ProductDiagnosis.error') : $t('ProductDiagnosis.normal') }}
    </span>
          </template>
        </el-table-column>

        <el-table-column :label="$t('ProductDiagnosis.treatmentMeasures')" prop="measures">
        </el-table-column>

        <el-table-column :label="$t('ProductDiagnosis.helpManual')" prop="manual">

          <template #default="scope">
            <span v-if="!scope.row.manual_link">{{ scope.row.manual }}</span>
            <a v-else :href="scope.row.manual_link" target="_blank"
               style="text-decoration: none;color: rgb(0,154,68)">{{ scope.row.manual }}</a>
          </template>
        </el-table-column>
      </el-table>
    </div>

  </div>
  <div class="button-container">
    <el-button class="custom-reset-button" @click="showFeedbackDialog">{{ $t('ProductDiagnosis.feedback') }}</el-button>

    <el-button :loading="isLoading" :disabled="isLoading" class="button-background-color" @click="downloadPdf">
      {{ $t('ProductDiagnosis.download') }}
    </el-button>
  </div>

  <el-dialog
      :title="$t('ProductDiagnosis.feedback')"
      v-model="feedbackDialogVisible"
      width="500"
      style="text-align: left"
  >
    <hr class="top-separator"/>

    <el-form ref="feedbackForm" label-position="top" :model="feedback" :rules="rules">
      <el-form-item :label="$t('ProductDiagnosis.contactPerson')" prop="contactPerson">
        <el-input v-model="feedback.contactPerson" :placeholder="$t('ProductDiagnosis.enter')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('ProductDiagnosis.contactPhone')" prop="contactPhone">
        <el-input v-model="feedback.contactPhone" :placeholder="$t('ProductDiagnosis.enter')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('ProductDiagnosis.note')">
        <el-input type="textarea" v-model="feedback.note" :placeholder="$t('ProductDiagnosis.enter')"></el-input>
      </el-form-item>
    </el-form>
    <hr class="dialog-separator"/>

    <div class="dialog-footer">
      <el-button class="custom-reset-button" @click="handleCancel">{{
          $t('ProductDiagnosis.cancel')
        }}
      </el-button>
      <el-button class="button-background-color" @click="sendFeedback">{{ $t('ProductDiagnosis.confirm') }}</el-button>
    </div>
  </el-dialog>

</template>

<script>

import {postDiagnoses, sendFeedback} from "@/api/api";

export default {
  data() {
    return {
      batteryImgUrl: require("@/assets/battery.webp"),
      dashboardImgUrl: require("@/assets/dashboard.webp"),

      // 初始化为空对象，稍后从LocalStorage中填充
      loadedReportData: {},
      selfCheckData: [],
      dynamicData: [],
      displayInfo: null,
      batteryInfo: null,
      motorInfo: null,
      motorTypeAndModelData: null,
      displayTypeAndModelData: null,
      motorConfigData: null,
      motorHistoryError: null,

      feedbackDialogVisible: false,
      feedback: {
        contactPerson: '',
        contactPhone: '',
        note: ''
      },
      rules: {
        contactPerson: [
          {required: true, message: this.$t('ProductDiagnosis.enterContactName'), trigger: 'blur'}
        ],
        contactPhone: [
          {required: true, message: this.$t('ProductDiagnosis.enterContactPhone'), trigger: 'blur'},
          {pattern: /^[0-9]+$/, message: this.$t('ProductDiagnosis.contactPhoneMustBeNumeric'), trigger: 'blur'}
        ]
      },

      convertedData: null, // 存储转换后的数据
      downloadUrl: null,
      urlKey: null,
      isLoading: true,
    }
  },
  props: {
    reportData: Object,
  },
  mounted() {
    const storedRoprtData = localStorage.getItem('reportData');

    if (storedRoprtData) {
      this.loadedReportData = JSON.parse(storedRoprtData);
      this.displayInfo = this.loadedReportData.displayInfo;
      this.batteryInfo = this.loadedReportData.batteryInfo;
      this.motorInfo = this.loadedReportData.motorInfo;
      this.motorTypeAndModelData = this.loadedReportData.motorTypeAndModelData;
      this.displayTypeAndModelData = this.loadedReportData.displayTypeAndModelData;
      this.motorConfigData = this.loadedReportData.motorConfigData;
      this.motorHistoryError = this.loadedReportData.motorHistoryError;
      this.dynamicData = this.loadedReportData.dynamicData;
      this.selfCheckData = this.loadedReportData.selfCheckData;
    }

    this.sendConvertedData(); // 发送转换后的数据
    this.isLoading = false; // 数据加载完成后，解除loading状态
  },
  computed: {
    motorImgUrl() {
      if (this.motorTypeAndModelData?.motorType === 'mid') {
        return require("@/assets/image/mid-motor.png");
      } else if (this.motorTypeAndModelData?.motorType === 'hub') {
        return require("@/assets/image/hub-motor.png");
      } else {
        return require("@/assets/image/mid-motor.png");
      }
    },
    currentTime() {
      return new Date().toLocaleString(); // 格式化当前时间
    },
    displayedLevels() {
      const levels = [];
      const maxLevel = this.motorConfigData?.maxLevel || 0;
      for (let i = 1; i <= maxLevel; i++) {
        levels.push({
          currentLevel: this.motorConfigData[`currentlevel${i}`] || '-',
          speedLevel: this.motorConfigData[`bikeSpeed${i}`] || '-',
        });
      }
      return levels;
    },
  },
  methods: {
    handleCancel() {
      this.$refs.feedbackForm.resetFields(); // 重置表单数据
      this.feedbackDialogVisible = false; // 关闭对话框
    },
    getLabel(type) {
      switch (type) {
        case 'brand':
          return this.$t('ProductDiagnosis.brandName');
        case 'dealer':
          return this.$t('ProductDiagnosis.dealerName');
        case 'oem':
          return this.$t('ProductDiagnosis.oemName');
        case 'user':
          return this.$t('ProductDiagnosis.userName');
        default:
          return '';
      }
    },
    async sendConvertedData() {
      const convertedData = this.convertDataForSubmission(); // 确保这个方法返回转换后的数据
      try {
        const response = await postDiagnoses({content: convertedData, lang: this.$store.getters.currentLanguage});
        // 可以添加一些用户提示，例如弹出消息通知
        this.downloadUrl = response.url;
        this.urlKey = response.key;
      } catch (error) {
        console.error("Failed to send data:", error);
      }
    },
    deviceName(deviceCode) {
      const deviceName = {
        '1': this.$t('ProductDiagnosis.brakeSensor'),
        '2': this.$t('ProductDiagnosis.wheelSensor'),
        '3': this.$t('ProductDiagnosis.pedalSensor'),
        '4': this.$t('ProductDiagnosis.handlebarSensor'),
        '5': this.$t('ProductDiagnosis.torqueSensor'),
      };
      return deviceName[deviceCode] || this.$t('ProductDiagnosis.unknownDevice');
    },
    showFeedbackDialog() {
      this.feedbackDialogVisible = true;
    },
    async sendFeedback() {
      await this.$refs.feedbackForm.validate(async (valid) => {
        if (valid) {
          // 创建后端期望的数据格式
          const feedbackData = {
            contact: this.feedback.contactPerson, // 映射为后端需要的contact字段
            phone: this.feedback.contactPhone, // 映射为后端需要的phone字段
            remark: this.feedback.note, // 映射为后端需要的remark字段
            file: this.urlKey // 临时的文件链接，您可以根据需要更改
          };

          try {
            const res = await sendFeedback(feedbackData); // 使用新的数据格式发送请求
            if (res.code === 0) {
              this.$message.success(this.$t('HistoryData.CustomerFeedback.feedbackAddedSuccess'));
            } else {
              this.$message.error(res.message);
            }
            // 处理响应，如关闭对话框，显示成功消息等
            this.feedbackDialogVisible = false;
            this.resetFeedbackData(); // 重置反馈数据
          } catch (error) {
            // 处理发送反馈时的错误，比如显示错误消息。
            console.error('Error sending feedback:', error);
          }
        } else {
          console.error('Validation failed!');
        }
      });
    },
    resetFeedbackData() {
      this.feedback = {
        contactPerson: '',
        contactPhone: '',
        note: ''
      };
    },
    async downloadPdf() {
      if (!this.downloadUrl) {
        console.error('No download URL available.');
        this.$message.error(this.$t('ProductDiagnosis.downloadLinkUnavailable'));
        return;
      }
      try {
        const response = await fetch(this.downloadUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', ''); // 添加 download 属性以触发下载
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // 清理 DOM
      } catch (error) {
        console.error('Error downloading PDF:', error);
        // 处理下载失败的情况
      }
    },

    // 数据转换方法
    convertDataForSubmission() {
      // 使用JSON方法创建深拷贝
      const data = JSON.parse(JSON.stringify(this.loadedReportData));

      // 确保 externaldevices 是一个数组
      if (data.motorConfigData && data.motorConfigData.externaldevices) {
        let devices = Array.isArray(data.motorConfigData.externaldevices)
            ? data.motorConfigData.externaldevices
            : (data.motorConfigData.externaldevices ? [data.motorConfigData.externaldevices] : []);

        data.motorConfigData.externaldevices = devices.map(device => {
          return this.deviceName(device); // 使用已存在的deviceName方法
        }).join(', '); // 将数组转换为逗号分隔的字符串
      }

      // 转换selfCheckData，只保留必要字段
      if (data.selfCheckData) {
        data.selfCheckData = data.selfCheckData.map(item => ({
          errName: item.errName,
          isErrorName: item.isErrorName,
          errNameStr: item.isErrorName === 1 ? this.$t('ProductDiagnosis.error') : item.isErrorName === 2 ? this.$t('ProductDiagnosis.warning') : this.$t('ProductDiagnosis.normal'),
          productType: item.productType === 1 ? this.$t('ProductDiagnosis.dashboard') : (item.productType === 0 ? this.$t('ProductDiagnosis.motor') : this.$t('ProductDiagnosis.motor')),

          document_name: item.manual || "", // 提供默认值
          document_url: item.manual_link || ""    // 提供默认值
        }));
      }

      //加动态测试的isErrorName的字符串显示字段
      if (data.dynamicData) {
        data.dynamicData = data.dynamicData.map(item => ({
          ...item,// 保留所有其他字段
          errNameStr: item.isErrorName === 1 ? this.$t('ProductDiagnosis.error') : this.$t('ProductDiagnosis.normal'), // 添加中文字段
        }));
      }

      // 存储转换后的数据供展示，而不修改原始loadedReportData
      this.convertedData = data;
      // 返回转换后的数据
      return data;
    },

  },
}
</script>
<style scoped>

/* 针对品牌名称和经销商名称的左对齐以及减少间距 */
.info-section > div {
  text-align: left;
  flex: 1; /* 假设您已经有flex布局 */
  min-width: 0; /* 防止内容溢出 */
  padding-right: 10px; /* 可以减少这个值来减小间隔 */
}

.main-title {
  font-size: 20px;
  color: #2c3e50;
}

/* 自定义 <hr> 元素的样式 */
hr {
  border: none;
  /* 移除默认边框 */
  border-top: 1px solid #f0f0f0;
  /* 添加自定义的上边框，可以根据需要调整颜色 */
}

.button-container {
  position: fixed;
  bottom: 0; /* 距底部的距离 */
  left: 0; /* 从左侧开始 */
  right: 0; /* 从左到右填满 */
  background-color: #ffffff; /* 或者您想要的任何颜色 */
  padding: 10px; /* 增加内边距确保按钮与边缘有间距 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 可选：添加阴影增强层次感 */
  z-index: 500; /* 确保按钮在最顶层 */
}

.content-wrapper {
  display: flex;
  align-items: center;
  margin-right: 20px;
  padding-left: 5px;
  margin-bottom: 20px;
}

.external-devices-list {
  list-style: none; /* 移除列表项的标记 */
  padding-left: 0; /* 移除默认的内边距 */
  display: flex; /* 设置列表为flex布局，实现横向排列 */
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

}

.motor-font {
  color: #000000D9;
  font-size: 16px;
  /* 设置字体大小为图片的大小 */
  margin: 0 0 0 12px;
  /* 调整图片和字体之间的间距 */
  font-family: "Pingfang sC";
}


.title-color {
  color: #00000073;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin-right: 4px;
}

.info-section {
  display: flex;
  justify-content: space-between;
}

.custom-color {
  color: #000000D9;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.flex-container {
  display: flex;
  gap: 20px; /* 控制列间距 */

}

.flex-container > div {
  /* 对列本身进行样式调整 */
  max-width: 50%; /* 设置最大宽度 */
  flex: 1; /* 添加这个使得子元素均匀分布 */
  padding-right: 10px; /* 或者您需要的任何数值 */
  text-align: left;

}

.report-container {
  max-width: 650px;
  margin: auto;
  border: 1px solid rgba(0, 0, 0, 0.05); /* 添加透明边框 */
  padding-left: 10px; /* 添加左侧内边距 */
  overflow-y: auto; /* 添加滚动条 */
  height: calc(100vh - 50px); /* 适应视口高度减去顶部导航栏的高度 */
  padding-bottom: 70px; /* 添加底部填充 */
}

.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 调整时间文本的右边距 */
.report-header > p {
  margin-right: 20px; /* 右边距 */
}

</style>